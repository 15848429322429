import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    > div {
      & + div {
        margin-left: 16px;
      }
    }
  }

  .loading {
    position: absolute;
    z-index: 2;
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.success};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Content = styled.div`
  overflow: auto;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TicketInfo = styled.div`
  .start_date,
  .deadline_date,
  .priority,
  .private,
  .monitor,
  .situation,
  .type {
    width: 15%;
  }

  .start_hour,
  .deadline_hour,
  .price {
    width: 10%;
  }

  .client,
  .recipient,
  .supplier {
    width: 35%;
  }

  .description {
    width: 100%;
  }
`;

export const Feedback = styled(Form)`
  padding: 10px;

  display: flex;
  flex-direction: column;
`;

export const SendFeedback = styled.div`
  margin-left: 16px;

  display: flex;
  align-items: center;

  .feedback_message {
    width: 40%;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 7px;
    border: 1px solid ${({ theme }) => theme.colors.success};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.success};
    }

    > svg {
      transform: rotate(45deg);
    }
  }
`;

export const UploadFile = styled.div`
  display: flex;
  align-items: center;
`;

export const FileName = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 30%;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    height: 30px;
    padding-top: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    background: transparent;
  }
`;

export const File = styled.div`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: 16px;
    opacity: 1;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
      cursor: pointer;
    }
  }
`;

export const FeedbackItem = styled.section`
  margin-top: 16px;
  position: relative;

  display: flex;
  align-items: flex-end !important;

  div {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.grey};
      opacity: 0.7;
    }

    input {
      padding-top: 10px;
      height: 30px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      background: transparent;
    }

    > textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }
  }

  div + div {
    margin-left: 16px;
  }

  button {
    position: absolute;
    top: 50%;
    background: none;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    padding: 2px;
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }
  }

  .date {
    margin-left: 24px;
  }

  .date,
  .user {
    width: 15%;
  }

  .file {
    width: 20%;
  }

  .content {
    width: 50%;
  }
`;
